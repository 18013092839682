/*
  This hook registers CSS variables, so that I can animate them. Typically used to animate color changes, but it's flexible enough to handle other use cases.
*/
import * as React from 'react';

// Since we're typically defining colors, I added a convenience shorthand: we can pass a string for the initial value. Alternatively, I can pass an object that defines `syntax` and `inherits`.
interface VariableMap {
  [key: `--${string}`]:
    | string
    | {
        syntax: string;
        inherits: boolean;
        initialValue: string;
      };
}

function useRegisterCSSVariables(vars: VariableMap) {
  const registeredProperties = React.useRef<{
    [key: `--${string}`]: true;
  }>({});

  React.useEffect(() => {
    Object.entries(vars).forEach(([property, value]) => {
      if (!isCorrectProperty(property)) {
        return;
      }

      const syntax =
        typeof value === 'string' ? '<color>' : value.syntax;
      const inherits =
        typeof value === 'string' ? true : value.inherits;
      const initialValue =
        typeof value === 'string' ? value : value.initialValue;

      // Skip properties which have previously been registered.
      // This is a global operation that only needs to be done once.
      if (registeredProperties.current[property]) {
        return;
      }

      // With client-side routing, it's possible that this property has already been registered, even if this is the very first render. Frustratingly, the DOM does not have a built-in API to check if the property has been registered. So we need to use a try/catch.
      try {
        window.CSS.registerProperty({
          name: property,
          syntax,
          inherits,
          initialValue,
        });
      } catch (err) {
        // Ignore errors, since it's almost certainly just telling me that it's already declared. Plus, the worst case is that we don't get smooth color transitions.
      } finally {
        registeredProperties.current[property] = true;
      }
    });
  }, [vars]);
}

// TypeScript doesn't trust `Object.entries`, so we need a type guard:
function isCorrectProperty(key: any): key is keyof VariableMap {
  return typeof key === 'string' && key.startsWith('--');
}

export default useRegisterCSSVariables;
