'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';

import { BREAKPOINTS, SEARCH_MODAL_ID } from '@/constants';

import {
  useGlobalUIState,
  useGlobalUIActions,
} from '@/components/GlobalUIProvider';
import VisuallyHidden from '@/components/VisuallyHidden';
import ModalBase from '@/components/ModalBase';
import Paper from '@/components/Paper';
import MaxWidthWrapper from '@/components/MaxWidthWrapper';
import PressableButton from '@/components/PressableButton';
import IconDismiss from '@/components/Icons/IconDismiss';

import { MIN_CONTENT_HEIGHT } from './ModalSearch.constants';
import CloudBottom from './CloudBottom';
import SearchContentFallback from './SearchContentFallback';

const SearchContent = dynamic(() => import('./SearchContent'), {
  loading: SearchContentFallback,
});

const DISMISS_DURATION = 750;

interface Props {
  isOpen: boolean;
  selectedResultId: string | null;
  handleDismiss: () => void;
  handleSelectResult: (resultId: string) => void;
}

function ModalSearch({
  isOpen,
  selectedResultId,
  handleDismiss,
  handleSelectResult,
}: Props) {
  const supportsViewTransitions =
    typeof document !== 'undefined' &&
    // @ts-ignore
    typeof document.startViewTransition !== 'undefined';

  // This component is a bit overengineered 😅.
  // The modal can be dismissed by clicking the "X" or hitting Escape, and when that happens, we consider it "retracted". However, if the user clicks a search result, we *don't* consider it "retracted". This is because, in that case, we handle the close animation using View Transitions API (in supported browsers).
  const isRetracted = !isOpen && !selectedResultId;

  return (
    <ModalBase
      returnFocus
      allowScroll
      skipPortal
      isOpen={isOpen}
      overlayProps={{
        enterDuration: 300,
        openOpacity: 0.4,
      }}
      dismissDuration={
        // When clicking a search result, we want to immediately unmount the modal so that View Transitions can do its thing (at least, when supported).
        selectedResultId && supportsViewTransitions
          ? 0
          : DISMISS_DURATION
      }
      // Things look funky if the user presses "Escape" right after opening the search. Block this functionality for 1 second.
      enableDismissAfter={1000}
      handleDismiss={handleDismiss}
    >
      <Modal
        data-has-selected-result={String(!!selectedResultId)}
        style={{
          transform: `translateY(${isRetracted ? '-100%' : '0%'})`,
        }}
      >
        <ContentWrapper
          maxWidth={686}
          style={{
            opacity: isRetracted ? 0 : 1,
            transition: isRetracted ? 'opacity 300ms' : undefined,
          }}
        >
          <SearchContent
            selectedResultId={selectedResultId}
            handleSelectResult={handleSelectResult}
            handleDismiss={handleDismiss}
          />
        </ContentWrapper>

        <DesktopCloseButton
          minPressDuration={175}
          onClick={handleDismiss}
        >
          {({ isBooped, isPressed }) => (
            <>
              <IconDismiss
                size={24}
                isBooped={isBooped}
                isPressed={isPressed}
              />
              <VisuallyHidden>Dismiss</VisuallyHidden>
            </>
          )}
        </DesktopCloseButton>

        <StyledClouds isOpen={!isRetracted} />
      </Modal>
    </ModalBase>
  );
}

function ConnectedSearchModal() {
  const { modals } = useGlobalUIState();
  const { closeModal, closeAllModals } = useGlobalUIActions();

  const [selectedResultId, setSelectedResultId] = React.useState<
    string | null
  >(null);

  const isOpen = modals[SEARCH_MODAL_ID]?.isOpen || false;

  // After the search modal is closed, reset `selectedResultId`.
  React.useEffect(() => {
    if (isOpen) {
      return;
    }

    window.setTimeout(() => {
      setSelectedResultId(null);
    }, DISMISS_DURATION + 1);
  }, [isOpen]);

  const handleDismiss = React.useCallback(() => {
    closeModal(SEARCH_MODAL_ID);
  }, [closeModal]);

  const handleSelectResult = React.useCallback(
    (resultId: string) => {
      setSelectedResultId(resultId);

      // Weird edge-case: if the user opens the mobile menu then the search, and then searches for something, we want to close both the search modal AND the menu.
      closeAllModals();
    },
    [closeAllModals]
  );

  return (
    <ModalSearch
      isOpen={isOpen}
      selectedResultId={selectedResultId}
      handleDismiss={handleDismiss}
      handleSelectResult={handleSelectResult}
    />
  );
}

const Modal = styled(Paper)`
  --scrollbar-bg: var(--color-background);
  --scrollbar-thumb: var(--color-gray-400);
  --scrollbar-thumb-hover: var(--color-gray-500);
  --extend-by: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh + var(--extend-by));
  max-height: calc(100svh + var(--extend-by));
  /*
  There's a springy animation which causes it to overshoot. We need to make the modal taller to hide this.
  */
  padding-top: var(--extend-by);
  margin-top: calc(var(--extend-by) * -1);

  /*
    So, the animations on this fella are super complicated:
    • Enter animations are dealt with via CSS keyframe animations
    • Exit animations, when dismissing, are via CSS transition
      (this works because the unmount is artificially delayed inside BaseModal)
    • Clicking a link causes the modal to close immediately, and so we animate that via View Transitions (in supported browsers).
  */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-15%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* Simpler enter animation for "reduced motion" */
  animation: fadeIn 500ms;

  /*
      Due to a bug in Linaria, the actual view transition stuff is handled in "/app/globals.css" for now.
    */
  view-transition-name: search-modal;

  @media (prefers-reduced-motion: no-preference) {
    transition: transform 500ms cubic-bezier(0.56, 0.1, 0.85, 0.87);
    animation: slideIn var(--springy-spring-duration) backwards;
    animation-timing-function: var(--springy-spring-easing);
    animation-delay: 100ms;
  }

  /* Dang it, Linaria! */
  && {
    border-radius: 0;
  }

  @supports not (view-transition-name: someVal) {
    &[data-has-selected-result='true'] {
      transition: opacity 300ms;
      transition-delay: 200ms;
      opacity: 0;
    }
  }
`;

const ContentWrapper = styled(MaxWidthWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-block: 32px;
  view-transition-name: search-modal-content;

  /*
    HACK: 'ContentWrapper' contains a dynamically-loaded component, ('SearchContent') with a fallback ('SearchContentFallback'). To avoid a layout shift, this container will have a minimum size which is just larger than the size of the dynamic content. This is a "magic number" that will need to be tweaked if/when SearchContent is updated with new UI.
  */
  min-height: ${MIN_CONTENT_HEIGHT}rem;

  /*
    HACK: I want this element to be clamped to its parent container, without it becoming a scroll container (the child does that). Weirdly, "overflow: hidden" solves this.
  */
  overflow: hidden;

  @supports not (view-transition-name: someVal) {
    ${Modal}[data-has-selected-result='true'] & {
      transition: opacity 300ms;
      opacity: 0;
    }
  }
`;

const DesktopCloseButton = styled(PressableButton)`
  position: absolute;
  /* HACK: The modal is extended above the viewport, and so we need to slide this close button down so that it is visible*/
  top: var(--extend-by);
  right: 16px;
  padding: 16px;
  animation: fadeIn 400ms both;
  animation-delay: 300ms;
  border-radius: 1000px;

  /* On smaller screens, we render a separate close button inside SearchBox */
  @media ${BREAKPOINTS.mdAndSmaller} {
    display: none;
  }
`;

const StyledClouds = styled(CloudBottom)`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* HACK: Fix weird gap in Safari (requires 2px instead of 1 for unknown reasons) */
  transform: translateY(calc(100% - 2px));
`;

export default React.memo(ConnectedSearchModal);
