import { styled } from '@linaria/react';

import Spinner from '@/components/Spinner';

function SearchContentFallback() {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-600);
  animation: fadeIn 400ms both;
  /* Ideally, the search will load quickly and the spinner will never be shown. But if a bit of time passes and we’re still rendering the fallback, we should give the user some notice that something is loading. */
  animation-delay: 750ms;
`;

export default SearchContentFallback;
