'use client';

import * as React from 'react';

import {
  LIGHT_COLORS,
  DARK_COLORS,
  COLOR_SWAP_TRANSITION,
  COLOR_THEME_COOKIE_NAME,
  DEFAULT_COLOR_MODE,
  ColorMap,
} from '@/constants';
import useRegisterCSSVariables from '@/hooks/use-register-css-variables';

import { UserPreferencesContext } from '@/components/UserPreferencesProvider';

const COLORS_BY_THEME = {
  light: LIGHT_COLORS,
  dark: DARK_COLORS,
};

const { duration, timingFunction } = COLOR_SWAP_TRANSITION;

interface Props {
  headContent?: React.ReactNode;
  children: React.ReactNode;
}

function HtmlRoot({ headContent, children }: Props) {
  const { colorMode } = React.useContext(UserPreferencesContext);
  const colorObject: ColorMap = COLORS_BY_THEME[colorMode];

  useRegisterCSSVariables(colorObject);

  return (
    <html
      // The snippet below may edit the `data-color-mode` attribute on this element, which causes a hydration mismatch. Ignore it; we don't *want* React to try and flip it back.
      suppressHydrationWarning
      lang="en"
      data-color-mode={colorMode}
      style={{
        ...colorObject,
        '--color-swap-duration': `${duration}ms`,
        '--color-swap-timing-function': timingFunction,
        colorScheme: colorMode,
        transition: `
          --color-background ${duration}ms ${timingFunction},
          --color-text ${duration}ms ${timingFunction}
        `,
      }}
    >
      <head>
        <script
          dangerouslySetInnerHTML={{
            __html: THEME_INITIALIZER_SCRIPT,
          }}
        />

        {headContent}

        {/*
          To avoid a FOUC, we need to include the CSS generated as part of SSR. This code works via context, sorta like styled-components, collecting the styles that have been rendered.

          The trouble is that the sandboxes are lazy-loaded with next/dynamic, which means it's hit-or-miss; sometimes it works, and sometimes only SOME of the styles get loaded. It gets fixed after hydration, but there's still a FOUC during SSR.

          And so: I've instead examined the <style> tag in-browser after hydration, copied all of the CSS, made some tweaks, and passed it into `headContent`. Lifted into the parent, `layout.tsx`, since there's no sense doing it in a Client Component, where all that static CSS gets included in the bundle.

          THIS MEANS THAT I'll NEED TO REPEAT THIS PROCESS WHENEVER I CHANGE SANDPACK STYLES.

          Unfortunately, the CSS that Sandpack generates includes a bunch of nonsense (possibly invalid?), and the SSR'ed result doesn't look great by default. I ran the CSS through a "prettifier" and changed some stuff:

            • All of the CSS variables were duplicated between .light and .sp-123456789. During SSR, there is no .sp-123456789 class, so I moved them all to the ".light" class.
            • There's some weird .sxs stuff. I removed it all
            • Things are weirdly behind empty @media queries. I flattened it, removing all at-rules.
        */}
      </head>

      {children}
    </html>
  );
}

// This script initializes the color theme.
//
// The priorities here are:
// 1. Have they saved a preference? If so, use that.
// 2. If not, check their operating system preference.
// 3. If they have neither, use the default theme.
//
// The server-rendered HTML uses `DEFAULT_COLOR_MODE`, so the HTML comes preloaded with all of the CSS variables and stuff. If the selected color mode matches, we don't have to do any work. Otherwise, we'll replace all of the CSS variables and the `data-color-mode` attribute.

const ALT_COLOR_MODE =
  DEFAULT_COLOR_MODE === 'light' ? 'dark' : 'light';

const THEME_INITIALIZER_SCRIPT = `
(function() {
  const DEFAULT_MODE = '${DEFAULT_COLOR_MODE}';

  let colorMode;
  let cookies = document.cookie ? document.cookie.split('; ') : [];

  cookies.some((cookiePair) => {
    const [key, value] = cookiePair.split('=');

    if (key === '${COLOR_THEME_COOKIE_NAME}') {
      colorMode = value;
      return true;
    }
  });

  if (!colorMode) {
    colorMode =
      window.matchMedia('(prefers-color-scheme: dark)')?.matches
        ? 'dark'
        : 'light';
  }

  // Ignore any values other than 'light' and 'dark';
  if (!['light', 'dark'].includes(colorMode)) {
    colorMode = DEFAULT_MODE;
  }

  if (colorMode === DEFAULT_MODE) {
    return;
  }

  // At this point, we’ve identified their preferred color mode, and it doesn't match the color mode used during SSR. We need to overwrite all of the colors.
  const colorMap = ${JSON.stringify(COLORS_BY_THEME[ALT_COLOR_MODE])};
  const d = document.documentElement;

  d.setAttribute('data-color-mode', colorMode);
  Object.entries(colorMap).forEach(([key, value]) => {
    d.style.setProperty(key, value);
  });
})();
`;

export default HtmlRoot;
