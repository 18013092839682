import { styled } from '@linaria/react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
}

function CloudBottom({ isOpen, ...delegated }: Props) {
  return (
    <Wrapper {...delegated}>
      <Svg
        viewBox="0 0 4113 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        style={{
          transform: isOpen
            ? 'scale(1, 2) translate(0%, var(--final-y-translate))'
            : 'scale(1, 0) translate(0%, 0%)',
        }}
      >
        <path
          d="M1825 113C1837.5 112 1874.5 145 2058.5 145.5C2242.5 146 2273 119.5 2288.5 119C2304 118.5 2312 140 2519.5 129.5C2727 119 2739.5 81.5 2748.5 82.5C2757.5 83.5 2821.5 105 2980.5 105C3139.5 105 3198 65 3211.5 61C3225 57 3230 91 3447.5 83.5C3665 76 3663 27.5 3675 29.5C3687 31.5 3710 76 3904 76C4098 76 4088 22 4098 19C4102.46 17.6626 4104.43 23.0822 4112.91 29.5V0.5H0.416992V28C24.7581 17.693 38.7983 8.78176 45.5013 9.5C59.5002 11 60.0014 72 226.501 72.5C393.001 73 432.003 19.5 440.502 18C449 16.5 474.502 77.5 669.002 85C863.502 92.5 889.003 56 902.502 56C916 56 937.002 92 1132.5 102.5C1328 113 1353 85 1363.5 85C1374 85 1391 124.5 1596 131.5C1801 138.5 1812.5 114 1825 113Z"
          fill="var(--color-background)"
        />
      </Svg>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const Svg = styled.svg`
  --initial-x-scale: 0.8;
  --final-y-translate: -30%;
  display: block;
  min-width: 2000px;
  transform-origin: top center;
  will-change: transform;
  pointer-events: none;
  animation: bubbleIn 600ms backwards;
  transition: transform 1000ms;

  path {
    pointer-events: auto;
  }

  @keyframes bubbleIn {
    from {
      transform: scale(var(--initial-x-scale), 0) translate(0%, 0%);
    }
    to {
      transform: scale(1, 2) translate(0%, var(--final-y-translate));
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    transition: none;
  }

  /*
    To reduce the overall height of the cloud, we slide it up by 30%. The cloud bulges out in the middle, but there isn't enough leeway on very large monitors (>1500px). So, on those large monitors, we won't slide it up at all.
  */
  @media (min-width: 90rem) {
    --final-y-translate: 0%;
  }

  /* Another fun little effect is that the cloud widens horizontally as it grows. On even larger screens (1600px+), this creates a jagged edge since the SVG isn't big enough when shrunk down by 20%. Remove this effect. */
  @media (min-width: 100rem) {
    --initial-x-scale: 0.9;
  }
  @media (min-width: 115rem) {
    --initial-x-scale: 1;
  }
`;

export default CloudBottom;
