import * as React from 'react';

import useBoopMinimal from '@/hooks/use-boop-minimal';

type ButtonWithoutChildren = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  'children'
>;

interface ChildProps {
  isPressed: boolean;
  isBooped: boolean;
}

interface Props extends ButtonWithoutChildren {
  children: (childProps: ChildProps) => React.ReactNode;
  minPressDuration?: number;
}

function PressableButton({
  minPressDuration,
  children,
  ...delegated
}: Props) {
  const [isPressed, setIsPressed] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const isBooped = useBoopMinimal(isHovering);

  // So: If the user taps REALLY quickly, it makes the animation a bit funky and asymmetrical, since the icon isn't fully flattened when it starts animating to the next state. To fix this, we'll enforce a minimum amount of time that we'll spend in the `pressed` state, and track it with refs:
  const pressedTimestamp = React.useRef<number | null>(null);
  const timeoutRef = React.useRef<number | null>(null);

  // If the component is unmounted mid-press (unlikely), clear the timeout.
  React.useEffect(() => {
    return () => {
      if (typeof timeoutRef.current === 'number') {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <button
      {...delegated}
      onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) => {
        setIsHovering(true);
        delegated.onMouseEnter?.(event);
      }}
      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => {
        setIsHovering(false);
        delegated.onMouseLeave?.(event);
      }}
      onPointerDown={(
        event: React.PointerEvent<HTMLButtonElement>
      ) => {
        // Start the clock, measure when this event happened:
        pressedTimestamp.current = Date.now();

        delegated.onPointerDown?.(event);
        setIsPressed(true);

        // If they tap REALLY QUICKLY, they might re-open it before the `closed` timeout even fires. Interrupt this scheduled timeout by cancelling it.
        if (typeof timeoutRef.current === 'number') {
          window.clearTimeout(timeoutRef.current);
        }
      }}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        // As mentioned above, there's a minimum amount of time that we'll spend in the `opening`/`closing` state. We’ll measure the amount of time here, and postpone this event if not enough time has passed.
        const MIN_TIME = minPressDuration || 0;

        const remainingTime = pressedTimestamp.current
          ? MIN_TIME - (Date.now() - pressedTimestamp.current)
          : 0;

        if (remainingTime <= 0) {
          delegated.onClick?.(event);
          setIsPressed(false);
          return;
        }

        timeoutRef.current = window.setTimeout(() => {
          delegated.onClick?.(event);
          setIsPressed(false);
        }, remainingTime);
      }}
      onPointerLeave={(
        event: React.PointerEvent<HTMLButtonElement>
      ) => {
        delegated.onPointerLeave?.(event);
        setIsPressed(false);
      }}
    >
      {children({ isBooped, isPressed })}
    </button>
  );
}

export default PressableButton;
