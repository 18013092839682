import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/typeface-sriracha@1.1.13/node_modules/typeface-sriracha/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/HtmlRoot.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/layout.linaria.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/FathomAnalytics/FathomAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","GlobalUIContext","GlobalUIDispatchContext","useGlobalUIState","useGlobalUIDispatch","useGlobalUIActions"] */ "/vercel/path0/src/components/GlobalUIProvider/GlobalUIProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ModalSearch/ModalSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","SetNavigationTrackerContext","NavigationTrackerContext"] */ "/vercel/path0/src/components/NavigationTrackerProvider/NavigationTrackerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/SetupViewTransitions/SetupViewTransitions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","UserPreferencesContext","SetUserPreferencesContext","useColorMode"] */ "/vercel/path0/src/components/UserPreferencesProvider/UserPreferencesProvider.tsx");
