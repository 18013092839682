'use client';

import * as React from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { load, trackPageview } from 'fathom-client';

function TrackPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Load the Fathom script on mount
  React.useEffect(() => {
    load('IWUODFHK', {
      auto: false,
      includedDomains: ['www.joshwcomeau.com', 'joshwcomeau.com'],
    });
  }, []);

  // Record a pageview when route changes
  React.useEffect(() => {
    if (!pathname) return;

    trackPageview({
      url: pathname + searchParams?.toString(),
      referrer: document.referrer,
    });
  }, [pathname, searchParams]);

  return null;
}

export default function Fathom() {
  return (
    <React.Suspense fallback={null}>
      <TrackPageView />
    </React.Suspense>
  );
}
