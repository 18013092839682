'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';

import {
  getFirstLineProps,
  getThirdLineProps,
} from './IconHamburger.helpers';

interface Props extends React.HTMLAttributes<SVGElement> {
  size?: number;
  isPressed: boolean;
  isBooped: boolean;
}

export function IconDismiss({
  size = 20,
  isPressed,
  isBooped,
  ...delegated
}: Props) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const sharedConfig = {
    tension: 300,
    friction: 16,
    clamp: !!isPressed,
  };

  const firstLineProps = useSpring({
    ...getFirstLineProps(
      isPressed && !prefersReducedMotion ? 'closing' : 'open',
      isBooped
    ),
    config: sharedConfig,
  });
  const thirdLineProps = useSpring({
    ...getThirdLineProps(
      isPressed && !prefersReducedMotion ? 'closing' : 'open',
      isBooped
    ),
    config: sharedConfig,
  });

  return (
    <Svg
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...delegated}
    >
      <animated.line {...firstLineProps} />
      <animated.line {...thirdLineProps} />
    </Svg>
  );
}

const Svg = styled.svg`
  stroke: currentColor;
  display: block;
  overflow: visible;

  line {
    transform-origin: center center;
  }
`;

export default IconDismiss;
